import "./App.css";

import Navbar from "./components/Navbar";
import Product from "./components/Product";
import Feature from "./components/Feature";
import ProductFeature from "./components/ProductFeature";
import Pricing from "./components/Pricing";
import Testimonial from "./components/Testimonial";
import Banner from "./components/Banner";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Navbar />
      <Product />
      <Feature />
      <ProductFeature />
      <Pricing />
      <Testimonial />
      <Banner />
      <Footer />
    </div>
  );
}

export default App;
