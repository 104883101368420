import React from "react";

import ImageFeature1 from "../assets/img/feature-1.png";
import ImageFeature2 from "../assets/img/feature-2.png";
import ImageFeature3 from "../assets/img/feature-3.png";

import { BsArrowRight } from "react-icons/bs";

const Feature = () => {
  return (
    <section className="min-h-screen max-w-[1140px] mx-auto ">
      <div className="grid grid-cols-12 lg:gap-12 mt-32">
        <div className="lg:col-span-6 col-span-12 text-center lg:text-left mx-6">
          <h5 className="uppercase text-xl text-[#BDBDBD] mb-5">
            Alwalys online
          </h5>
          <h1 className="font-bold text-[38px] lg:text-[96px] text-[#292830] leading-none">
            Real-time support with cloud
          </h1>
          <p className="text-xl text-[#BDBDBD] my-8">
            Tellus lacus morbi sagittis lacus in. Amet nisl at mauris enim
            accumsan nisi, tincidunt vel. Enim ipsum, amet quis ullamcorper eget
            ut.
          </p>
          <button className="transition text-[#FF7235]">
            <div className="flex justify-center items-center">
              Learn More
              <span className="ml-3">
                <BsArrowRight />
              </span>
            </div>
          </button>
        </div>
        <div className="lg:col-span-6 col-span-12">
          <img
            src={ImageFeature1}
            alt=""
            className="w-[70%] mx-auto lg:w-[100%] mt-8 lg:mt-0"
          />
        </div>
      </div>
      <div className="grid grid-cols-12 lg:gap-12 mt-32">
        <div className="lg:col-span-6 col-span-12 order-2 lg:order-1">
          <img
            src={ImageFeature2}
            alt=""
            className="w-[70%] mx-auto lg:w-[100%] mt-8 lg:mt-0"
          />
        </div>
        <div className="lg:col-span-6 col-span-12 text-center lg:text-left mx-6 order-1 lg:order-2">
          <h5 className="uppercase text-xl text-[#BDBDBD] mb-5">
            free some cost
          </h5>
          <h1 className="font-bold text-[38px] lg:text-[96px] text-[#292830] leading-none">
            Save cost for you and family
          </h1>
          <p className="text-xl text-[#BDBDBD] my-8">
            Tellus lacus morbi sagittis lacus in. Amet nisl at mauris enim
            accumsan nisi, tincidunt vel. Enim ipsum, amet quis ullamcorper eget
            ut.
          </p>
          <button className="transition text-[#FF7235]">
            <div className="flex justify-center items-center">
              Learn More
              <span className="ml-3">
                <BsArrowRight />
              </span>
            </div>
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 lg:gap-12 mt-32">
        <div className="lg:col-span-6 col-span-12 text-center lg:text-left mx-6">
          <h5 className="uppercase text-xl text-[#BDBDBD] mb-5">Use anytime</h5>
          <h1 className="font-bold text-[38px] lg:text-[96px] text-[#292830] leading-none">
            Use anytime when you need
          </h1>
          <p className="text-xl text-[#BDBDBD] my-8">
            Tellus lacus morbi sagittis lacus in. Amet nisl at mauris enim
            accumsan nisi, tincidunt vel. Enim ipsum, amet quis ullamcorper eget
            ut.
          </p>
          <button className="transition text-[#FF7235]">
            <div className="flex justify-center items-center">
              Learn More
              <span className="ml-3">
                <BsArrowRight />
              </span>
            </div>
          </button>
        </div>
        <div className="lg:col-span-6 col-span-12">
          <img
            src={ImageFeature3}
            alt=""
            className="w-[70%] mx-auto lg:w-[100%] mt-8 lg:mt-0"
          />
        </div>
      </div>
    </section>
  );
};

export default Feature;
