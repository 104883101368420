import React from "react";

import { BsChevronDown } from "react-icons/bs";

import ImageIllustration from "../assets/img/banner-illustration.png";

const Banner = () => {
  return (
    <section className="mx-auto my-50">
      <div className="lg:min-h-[750px] banner text-center px-10 lg:px-0 py-20">
        <h1 className="col-span-8 font-bold text-[34px] lg:text-[72px] text-white leading-none">
          20M+ downloaded from 32
          <br />
          diffrent countires
        </h1>
        <p className="text-white text-md lg:text-3xl mt-4">
          Try demo for 7 days with full features.
        </p>

        <button className="mt-10 border px-[20px] py-[12px] rounded-md bg-white border-[#FF7235] text-[#FF7235]">
          <div className="flex justify-center items-center">
            Try Free Demo
            <span className="ml-3">
              <BsChevronDown />
            </span>
          </div>
        </button>
        <img src={ImageIllustration} alt="" className="mx-auto mt-10 lg:mt-0" />
      </div>
    </section>
  );
};

export default Banner;
