import React from "react";

import Image from "../assets/img/product.png";
import Client1 from "../assets/img/client-1.svg";
import Client2 from "../assets/img/client-2.svg";
import Client3 from "../assets/img/client-3.svg";
import Client4 from "../assets/img/client-4.svg";
import Client5 from "../assets/img/client-5.svg";

const Product = () => {
  return (
    <section>
      <div className="product lg:h-[712px] flex justify-center items-end">
        <img
          src={Image}
          alt=""
          className="lg:max-w-[1140px] mx-auto max-w-[90%] mt-16"
        />
      </div>
      <div
        id="client-logo"
        className="max-w-[1140px] mx-auto grid grid-cols-12 lg:flex justify-between items-center bg-white py-14"
      >
        <img src={Client1} alt="" className="col-span-6 mx-auto my-5" />
        <img src={Client2} alt="" className="col-span-6 mx-auto my-5" />
        <img src={Client3} alt="" className="col-span-6 mx-auto my-5" />
        <img src={Client4} alt="" className="col-span-6 mx-auto my-5" />
        <img src={Client5} alt="" className="col-span-12 mx-auto my-5" />
      </div>
    </section>
  );
};

export default Product;
