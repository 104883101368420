import React from "react";

import Image from "../assets/img/hero.png";

import { BsChevronDown } from "react-icons/bs";

const Hero = () => {
  return (
    <div className="grid grid-flow-col lg:grid-cols-12 gap-12 items-center">
      <div className="col-span-6 flex flex-col py-20 my-3 px-5 lg:px-0">
        <h1 className="font-bold text-[80px] lg:text-[96px]  text-[#292830] leading-none">
          Track your Expenses to Save Money
        </h1>
        <p className="text-xl text-[#BDBDBD] my-8">
          Helps you to organize your income and expenses
        </p>
        <div className="flex mt-4">
          <button className="px-[15px] py-[12px] border hover:border-[#FF7235] rounded-md bg-[#FF7235] text-white hover:bg-white hover:text-[#FF7235] transition">
            <div className="flex justify-center items-center">
              Try Free Demo
              <span className="ml-3">
                <BsChevronDown />
              </span>
            </div>
          </button>
          <button className="text-[#BDBDBD] ml-8">
            — Web, iOs and Android
          </button>
        </div>
      </div>
      <div className="col-span-6 items-center">
        <img src={Image} alt="" className="w-full hidden lg:block" />
      </div>
    </div>
  );
};

export default Hero;
