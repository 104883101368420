import React from "react";

import Logo from "../assets/img/logo.png";

import { ImFacebook2 } from "react-icons/im";
import { FaTwitterSquare } from "react-icons/fa";
import { ImLinkedin } from "react-icons/im";

const Footer = () => {
  return (
    <footer className="lg:max-w-[1140px] mx-auto pb-10">
      <div className="lg:flex justify-between px-10 lg:px-0 py-20">
        <div className="mb-10 lg:mb-0">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="links mb-10 lg:mb-0">
          <h1 className="text-2xl uppercase mb-4">Links</h1>
          <ul>
            <li>Home</li>
            <li>About Us</li>
            <li>Careers</li>
            <li>Pricing</li>
            <li>Features</li>
            <li>Blog</li>
          </ul>
        </div>
        <div className="Legal mb-10 lg:mb-0">
          <h1 className="text-2xl uppercase mb-4">Legal</h1>
          <ul>
            <li>Terms of use</li>
            <li>Terms of conditions</li>
            <li>Privecy policy</li>
            <li>Cookie policy</li>
          </ul>
        </div>
        <div className="newsletter mb-10 lg:mb-0">
          <h1 className="text-2xl uppercase mb-4">Newsletter</h1>

          <p className="text-[#BDBDBD]">Over 25000 people have subscribed</p>
          <div className="my-2">
            <div class="container flex  items-center">
              <div class="relative">
                <input
                  type="text"
                  class="h-14 lg:w-96 pl-5 pr-20 rounded-lg z-0 border border-[#292830]"
                  placeholder="Enter your email"
                />
                <div class="absolute top-2 right-2">
                  <button class="h-10 w-24 text-white rounded-lg bg-[#FF7235]">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
            <p className="text-[#BDBDBD] text-sm mt-2">
              We don’t sell your email and spam
            </p>
          </div>
        </div>
      </div>
      <div className="lg:flex justify-between border-t pt-10 text-center lg:text-left">
        <div className="flex items-center justify-center gap-4 order-3">
          <ImFacebook2 />
          <FaTwitterSquare />
          <ImLinkedin />
        </div>
        <div className="order-1 mt-3 lg:mt-0">
          <a href="/">Privacy & Terms</a>
          <a href="/" className="ml-5">
            Contact Us
          </a>
        </div>
        <div className="order-2 mt-3 lg:mt-0">
          <p>Copyright @ 2022 Xpense</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
