import React from "react";

import Carousel from "./Carousel";

const Testimonial = () => {
  return (
    <section className="mx-auto mt-48">
      <div className="">
        <h1 className="col-span-8 font-bold text-[38px] lg:text-[72px] text-[#292830] leading-none text-center">
          We have millions of
          <br />
          best wishers
        </h1>
      </div>
      <Carousel />
    </section>
  );
};

export default Testimonial;
