import React from "react";

import Slider from "react-slick";

import Profile from "../assets/img/profile.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "100px",
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          centerMode: true,
          centerPadding: "50px",
        },
      },
    ],
  };
  return (
    <div className=" mx-auto overflow-hidden">
      <Slider {...settings} className="grid grid-cols-12 my-16 lg:my-32">
        <div className="col-span-12 lg:col-span-3 rounded-lg border bg-[#FF7235] border-[#F5F5F5] hover:shadow-lg transition">
          <div className="bg-white p-6 mt-3">
            <p className="">
              Eleifend fames amet, fames enim. Ullamcorper pellentesque ac
              volutpat nibh aliquet et, ut netus. Vel, fringilla sit eros
              pretium felis massa mauris, aliquam congue.
            </p>
            <div className="flex mt-4  items-center">
              <img src={Profile} alt="" />
              <h4 className="ml-2 font-bold">
                Cameron Williamson,{" "}
                <span className="font-normal text-[#BDBDBD]">CEO</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3 rounded-lg border bg-[#FFBE21] border-[#F5F5F5] hover:shadow-lg transition">
          <div className="bg-white p-6 mt-3">
            <p className="">
              Eleifend fames amet, fames enim. Ullamcorper pellentesque ac
              volutpat nibh aliquet et, ut netus. Vel, fringilla sit eros
              pretium felis massa mauris, aliquam congue.
            </p>
            <div className="flex mt-4  items-center">
              <img src={Profile} alt="" />
              <h4 className="ml-2 font-bold">
                Cameron Williamson,{" "}
                <span className="font-normal text-[#BDBDBD]">CEO</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3 rounded-lg border bg-[#4756DF] border-[#F5F5F5] hover:shadow-lg transition">
          <div className="bg-white p-6 mt-3">
            <p className="">
              Eleifend fames amet, fames enim. Ullamcorper pellentesque ac
              volutpat nibh aliquet et, ut netus. Vel, fringilla sit eros
              pretium felis massa mauris, aliquam congue.
            </p>
            <div className="flex mt-4  items-center">
              <img src={Profile} alt="" />
              <h4 className="ml-2 font-bold">
                Cameron Williamson,{" "}
                <span className="font-normal text-[#BDBDBD]">CEO</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3 rounded-lg border bg-[#3EC1F3] border-[#F5F5F5] hover:shadow-lg transition">
          <div className="bg-white p-6 mt-3">
            <p className="">
              Eleifend fames amet, fames enim. Ullamcorper pellentesque ac
              volutpat nibh aliquet et, ut netus. Vel, fringilla sit eros
              pretium felis massa mauris, aliquam congue.
            </p>
            <div className="flex mt-4  items-center">
              <img src={Profile} alt="" />
              <h4 className="ml-2 font-bold">
                Cameron Williamson,{" "}
                <span className="font-normal text-[#BDBDBD]">CEO</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3 rounded-lg border bg-[#BB7259] border-[#F5F5F5] hover:shadow-lg transition">
          <div className="bg-white p-6 mt-3">
            <p className="">
              Eleifend fames amet, fames enim. Ullamcorper pellentesque ac
              volutpat nibh aliquet et, ut netus. Vel, fringilla sit eros
              pretium felis massa mauris, aliquam congue.
            </p>
            <div className="flex mt-4  items-center">
              <img src={Profile} alt="" />
              <h4 className="ml-2 font-bold">
                Cameron Williamson,{" "}
                <span className="font-normal text-[#BDBDBD]">CEO</span>
              </h4>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
