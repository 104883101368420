import React from "react";

import { MdDevices } from "react-icons/md";
import { BsCloud } from "react-icons/bs";
import { MdOutlineBackpack } from "react-icons/md";

const ProductFeature = () => {
  return (
    <section className="min-h-screen max-w-[1140px] mx-auto mt-32">
      <div className=" grid grid-cols-12 justify-center items-center text-center lg:text-left mx-6">
        <h1 className="lg:col-span-8 col-span-12 font-bold text-[38px] lg:text-[96px] text-[#292830] leading-none ">
          The Product we work with.
        </h1>
        <p className="lg:col-span-4 col-span-12 text-xl text-[#BDBDBD] my-8">
          Tellus lacus morbi sagittis lacus in. Amet nisl at mauris enim aumsan
          nisi, tincidunt vel. Enim ipsum, at quis ullamcorper eget ut.
        </p>
      </div>
      <div className="grid grid-cols-12 justify-center items-center my-32 gap-8 mx-6">
        <div className="col-span-12 lg:col-span-4 text-center mx-auto py-16 px-10 rounded-lg shadow-md hover:shadow-xl transition">
          <div className="w-20 h-20 p-1 bg-[#FFFCF8] rounded-full flex justify-center items-center mx-auto">
            <MdDevices className="text-3xl mx-auto text-[#FFBD74]" />
          </div>
          <h2 className="text-3xl text-[#292830] my-4">Cross platform</h2>
          <p className="text-[#BDBDBD]">
            Elit esse cillum dolore eu fugiat nulla pariatur
          </p>
        </div>
        <div className="col-span-12 lg:col-span-4 text-center mx-auto py-16 px-10 rounded-lg shadow-md hover:shadow-xl transition">
          <div className="w-20 h-20 p-1 bg-[#FEF7F9] rounded-full flex justify-center items-center mx-auto">
            <BsCloud className="text-3xl mx-auto text-[#E2598B]" />
          </div>
          <h2 className="text-3xl text-[#292830] my-4">Cloud server</h2>
          <p className="text-[#BDBDBD]">
            Elit esse cillum dolore eu fugiat nulla pariatur
          </p>
        </div>
        <div className="col-span-12 lg:col-span-4 text-center mx-auto py-16 px-10 rounded-lg shadow-md hover:shadow-xl transition">
          <div className="w-20 h-20 p-1 bg-[#F6F3FE] rounded-full flex justify-center items-center mx-auto">
            <MdOutlineBackpack className="text-3xl mx-auto text-[#4D12EE]" />
          </div>
          <h2 className="text-3xl text-[#292830] my-4">Pure Javascript</h2>
          <p className="text-[#BDBDBD]">
            Elit esse cillum dolore eu fugiat nulla pariatur
          </p>
        </div>
      </div>
    </section>
  );
};

export default ProductFeature;
