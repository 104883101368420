import React from "react";

import { BiBriefcase } from "react-icons/bi";
import { BiCheck } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { MdOutlineCardMembership } from "react-icons/md";

const Pricing = () => {
  return (
    <section className="min-h-screen max-w-[1140px] mx-auto mt-48">
      <h1 className="col-span-8 font-bold text-[38px] lg:text-[72px] text-[#292830] leading-none text-center ">
        Choose your flexible plan.
      </h1>

      <div className="grid grid-cols-12 my-16 lg:my-32 gap-8 mx-5">
        <div className="col-span-12 lg:col-span-4 py-12 px-10 rounded-lg border border-[#F5F5F5] hover:shadow-lg transition">
          <BiBriefcase className="text-3xl text-[#3EC1F3]" />
          <h1 className="text-3xl font-semibold text-[#292830] my-5">
            Starter Plan
          </h1>
          <ul className="my-8">
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Store unlimited data</span>
            </li>
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Export to pdf, xls, csv</span>
            </li>
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Cloud server support</span>
            </li>
          </ul>
          <h1 className="text-3xl font-bold mt-5">
            $9.99/<span className="font-normal text-[#BDBDBD]">year</span>
          </h1>
          <p className="text-[#BDBDBD]">up to 3 user + 1.99 per user</p>
          <button className="mt-10 border px-[20px] py-[12px] rounded-md border-[#FF7235] text-[#FF7235] hover:bg-[#FF7235] hover:text-white transition">
            <div className="flex justify-center items-center">
              Get this
              <span className="ml-3">
                <BsArrowRight />
              </span>
            </div>
          </button>
        </div>
        <div className="col-span-12 lg:col-span-4 py-12 px-10 rounded-lg border border-[#F5F5F5] hover:shadow-lg transition">
          <MdOutlineCardGiftcard className="text-3xl text-[#FF7235]" />
          <h1 className="text-3xl font-semibold text-[#292830] my-5">
            Sliver Plan
          </h1>
          <ul className="my-8">
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Store unlimited data</span>
            </li>
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Export to pdf, xls, csv</span>
            </li>
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Cloud server support</span>
            </li>
          </ul>
          <h1 className="text-3xl font-bold mt-5">
            $19.99/<span className="font-normal text-[#BDBDBD]">year</span>
          </h1>
          <p className="text-[#BDBDBD]">up to 3 user + 1.99 per user</p>
          <button className="mt-10 border px-[20px] py-[12px] rounded-md bg-[#FF7235] text-white transition">
            <div className="flex justify-center items-center">
              Get this
              <span className="ml-3">
                <BsArrowRight />
              </span>
            </div>
          </button>
        </div>
        <div className="col-span-12 lg:col-span-4 py-12 px-10 rounded-lg border border-[#F5F5F5] hover:shadow-lg transition">
          <MdOutlineCardMembership className="text-3xl text-[#4D12EE]" />
          <h1 className="text-3xl font-semibold text-[#292830] my-5">
            Diamond Plan
          </h1>
          <ul className="my-8">
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Store unlimited data</span>
            </li>
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Export to pdf, xls, csv</span>
            </li>
            <li className="flex my-2">
              <BiCheck className="text-2xl mr-2 text-[#BDBDBD]" />
              <span className="text-xl">Cloud server support</span>
            </li>
          </ul>
          <h1 className="text-3xl font-bold mt-5">
            $29.99/<span className="font-normal text-[#BDBDBD]">year</span>
          </h1>
          <p className="text-[#BDBDBD]">up to 3 user + 1.99 per user</p>
          <button className="mt-10 border px-[20px] py-[12px] rounded-md border-[#FF7235] text-[#FF7235] hover:bg-[#FF7235] hover:text-white transition">
            <div className="flex justify-center items-center">
              Get this
              <span className="ml-3">
                <BsArrowRight />
              </span>
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
