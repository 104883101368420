import React from "react";

import Hero from "./Hero";
import Logo from "../assets/img/logo.png";

const Navbar = () => {
  return (
    <div className="min-h-screen max-w-[1140px] mx-auto">
      <header className="py-7 my-0.5">
        <nav className="grid grid-flow-col grid-cols-12 justify-between items-center">
          <img src={Logo} alt="Logo " className="col-span-3 ml-4 lg:ml-0" />
          <ul className="mx-2 col-span-4 col-start-5 lg:flex justify-between items-center hidden">
            <li>Features</li>
            <li>About Us</li>
            <li>Pricing</li>
            <li>Feedback</li>
          </ul>
          <div className="col-span-4 col-start-12 hidden lg:block">
            <button className="border px-[9px] py-[5px] rounded-md border-[#FF7235] text-[#FF7235] hover:bg-[#FF7235] hover:text-white transition">
              Request a demo
            </button>
          </div>
        </nav>
        <Hero />
      </header>
    </div>
  );
};

export default Navbar;
